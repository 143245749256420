$graphite: hsl(45, 17%, 19%);
$graphiteHighlight: hsl(45, 17%, 45%);
$darkGray: #252934;
$lightGray: hsl(0, 0%, 92%);
$lighterGray: hsl(0, 0%, 96%);
$gray: hsl(0, 0%, 88%);
$scrollBarWidth: 0.7vw;
$darkBlue: hsl(208, 40%, 30%);
$lightBlue: hsl(208, 60%, 95%);
$logoImgHeight: 6vh;
$mainContentWidth: 70vw;
$projectImgHeight: 25vw;

@font-face {
  font-family: "DIN";
  font-display: swap;
  src: url("../fonts/din1451alt.ttf") format("truetype");
}

::-webkit-scrollbar {
  width: $scrollBarWidth;
  background: $graphite;
}

::-webkit-scrollbar-thumb {
  background: $darkBlue;
}

* {
  box-sizing: border-box;
  font-family: "DIN", helvetica, sans-serif;
}

body,
html {
  margin: 0;
  padding: 0;
  font-size: 16px;
  @media screen and (max-aspect-ratio: 1/1) {
    font-size: 1.3rem;
  }
}

.navbar {
  top: -70px;
  transition: top 0.2s ease-out;

  .navbar-nav {
    .nav-link {
      color: $lightGray;
    }

    .nav-link:hover {
      color: white;
    }
  }

  .nav-item {
    margin: 0 2%;
    font-size: 1.1rem;
  }
}

.page {
  min-height: 100vh;
  margin: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  color: $graphite;
  background-color: $lightGray;
  text-align: center;

  .subHeader {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
    div {
      margin-top: 3em;
    }
  }

  .header {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  h1,
  h2 {
    cursor: default;
    margin: 1rem;
  }
}

#home {
  background-color: $darkGray;

  .dark-rectangle {
    background: black;
    opacity: 0.8;
    border-radius: 25px;
  }

  h1,
  h2 {
    color: white;
    margin: 2rem;
  }
  h1 {
    font-size: 3.5rem;
  }
  h2 {
    font-size: 3rem;
  }
  background-image: url(https://res.cloudinary.com/lkaratun/image/upload/q_50/w_1200/v1535470462/portfolio/vancouver_lions_gate.jpg);
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 1200px) {
    background-image: url(https://res.cloudinary.com/lkaratun/image/upload/q_50/w_1600/v1535470462/portfolio/vancouver_lions_gate.jpg);
  }
  @media screen and (min-width: 1600px) {
    background-image: url(https://res.cloudinary.com/lkaratun/image/upload/q_50/w_2400/v1535470462/portfolio/vancouver_lions_gate.jpg);
  }
  @media screen and (min-width: 2400px) {
    background-image: url(https://res.cloudinary.com/lkaratun/image/upload/q_50/w_3200/v1535470462/portfolio/vancouver_lions_gate.jpg);
  }

  #downArrow {
    width: 150px;
    height: 80px;

    .shape {
      fill: none;
      stroke: hsl(208, 40%, 30%);
      stroke-linecap: round;
      stroke-linejoin: bevel;
      stroke-width: 3px;
    }
  }

  #downArrow:hover .shape {
    stroke: white;
  }
}

#photo {
  display: block;
  object-fit: scale-down;
  object-position: 0 0;
  width: 50%;
  height: auto;
  border-radius: 50%;
  margin: 2% 4% 2% 2%;
  float: left;
  @media screen and (max-aspect-ratio: 1/1) {
    width: auto;
    height: auto;
    float: none;
    margin: 0 auto 5% auto;
  }
}

#about {
  padding: 0% 5%;
  box-sizing: content-box;
  justify-content: flex-start;
  align-items: center;

  #bio {
    margin: 2rem 0;
    max-width: 60rem;
    a {
      color: $darkBlue;
    }
  }

  .card-group {
    display: flex;
    flex-flow: row wrap;
    width: $mainContentWidth;
    margin-bottom: 5vh;
    @media screen and (max-width: 1400px) {
      width: 90%;
    }
    .card {
      display: block;
      min-width: 15rem;
      flex-shrink: 0;

      @media screen and (max-aspect-ratio: 1/1) {
        flex-shrink: 0;
        min-width: 60%;
      }
    }
    table.table {
      margin-bottom: 0;
    }
  }
}

#projects {
  height: auto;
  min-height: 50vh;
  background: $lighterGray;

  .projectsContainer {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: center;
  }

  h3 {
    min-width: 30vw;
    margin: 1rem;
  }
  .projectCard {
    width: $mainContentWidth;

    min-width: 25rem;
    box-sizing: border-box;
    background-color: $lightGray;
    margin: 1vh 1vh 3vh 1vh;
    padding: 1vh 2vh 1vh 4vh;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.48);

    @media screen and (max-width: 1400px) {
      width: 100%;
      padding: 1vh 2vh 1vh 2vh;
    }

    ul,
    ol {
      padding-left: 1em;
      box-sizing: border-box;
    }
    label,
    .inline-header {
      display: inline;
    }
    h5 {
      margin: 0.5rem 1rem 0 1rem;
    }
    a {
      color: $darkBlue;
    }
    article {
      text-align: left;
    }
    aside {
      width: 50%;
      float: right;
      position: relative;
      padding: 0.5vw;
      box-sizing: border-box;
      cursor: pointer;
      transition: all 0.2s ease-out;
      .enlarge {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 7%;
        border: 1px solid hsl(45, 17%, 19%);
        border-radius: 5px;
        padding: 1%;
        transform: scale(2) translate(-25%, -40%);
        opacity: 0;
        cursor: pointer;
        transition: all 0.1s ease-out;
      }
      .hidden {
        display: none;
      }
      &.expanded {
        width: 100%;
        transition: all 0.2s ease-out;
      }
      &:hover .enlarge {
        opacity: 0.5;
        transition: all 0.1s ease-out;
      }
      a {
        margin: 0.5em;
        display: inline-block;
        font-size: 1.2em;
      }
    }
    img {
      width: 100%;
    }

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: $darkBlue;
      opacity: 0.9;
      transition: all 0.25s ease-out;
      overflow: hidden;

      .project-link {
        opacity: 0;
        transition: all 0.25s ease-out;
      }

      .description {
        width: 80%;
        height: 100%;
        left: 10%;
        top: 30%;
        position: absolute;
        color: $lightBlue;
        overflow: hidden;
        font-size: 1rem;

        @media screen and (max-aspect-ratio: 1) {
          top: 5%;
          left: 5%;
        }

        .projectName {
          font-size: 135%;
          color: $lighterGray;
        }
      }
    }

    &:hover {
      .project-link {
        opacity: 1;
        transition: all 0.1s ease-out;
      }
    }
  }
}

#contacts {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  position: relative;
  min-height: 25vh;
  @media screen and (max-aspect-ratio: 1/1) {
    min-height: 25vh;
  }

  h4 {
    margin-top: 2rem;
  }
  .subHeader {
    margin: 0;
  }

  a {
    color: $graphite;
    object {
      width: 1.5rem;
      margin: 1rem;
      vertical-align: middle;
    }
  }

  a:hover {
    color: $graphiteHighlight;
    text-decoration: none;
  }
}

// Footer
#contacts::after {
  content: "";
  background: url(../images/vancouver_skyline.jpg);
  background-size: contain;
  background-repeat: repeat-x;
  background-position: bottom;
  opacity: 0.2;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  height: 100px;
}
